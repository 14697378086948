<template>
  <v-form>
    <v-row>
      <v-col cols="8">
        <v-text-field label="Email Address" v-model="email" class="purple-input" />
      </v-col>
      <v-col cols="4">
        <v-btn class="primary" @click="sendSignInLinkToEmail">Send invite link.</v-btn>
      </v-col>

      <v-col cols="12">
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">User details</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-switch
                  v-if="editedItem.isEnabled != undefined"
                  v-bind:disabled="checkIfLastOrgAdmin(editedItem) && !isSysUser"
                  v-model="editedItem.isEnabled"
                  :label="`Account active: ${editedItem.isEnabled.toString()}`"
                ></v-switch>
                <v-row>
                  <v-col cols="12">
                    <strong>Email:</strong>
                    {{ editedItem.email }}
                  </v-col>
                  <v-col cols="12">
                    <strong>Status:</strong>
                    {{ editedItem.status }}
                    <v-btn small @click="sendSignInLink(editedItem)"
                      >re-send invite</v-btn
                    >
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.roles"
                      v-bind:disabled="checkIfLastOrgAdmin(editedItem)"
                      :items="roles"
                      attach
                      chips
                      label="Roles"
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="close">Cancel</v-btn>
              <v-btn class="primary" @click="saveUser">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-switch v-model="isActive" label="Active Users" />
        <v-data-table
          id="list-table"
          v-bind:headers="[
            { text: 'Email', value: 'email' },
            { text: 'Roles', value: 'roles' },
            { text: 'Status', value: 'status' },
            { text: '', value: '' },
          ]"
          :items="users"
          :search="email"
          item-key="email"
        >
          <template slot="item" slot-scope="props">
            <template v-if="isActive">
              <tr v-show="props.item.isEnabled">
                <td text-xs-left>{{ props.item.email }}</td>
                <td text-xs-left>
                  <span v-for="(role, i) in props.item.roles" :key="i"
                    >{{ role }},&nbsp;</span
                  >
                </td>

                <td text-xs-left> {{  props.item.status  }} </td>

                <td>
                  <v-icon small class="mr-2" @click="editUser(props.item)"
                    >mdi-pencil</v-icon
                  >
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-show="!props.item.isEnabled">
                <td text-xs-left>{{ props.item.email }}</td>
                <td text-xs-left>
                  <span v-for="(role, i) in props.item.roles" :key="i"
                    >{{ role }},&nbsp;</span
                  >
                </td>

                <td text-xs-left>
                  {{  "Disabled" }}
                </td>

                <td>
                  <v-icon small class="mr-2" @click="editUser(props.item)"
                    >mdi-pencil</v-icon
                  >
                </td>
              </tr>
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    email: "",
    dialog: false,
    users: [],
    editedIndex: -1,
    editedItem: {},
    isActive: true,
    isSysUser: false,
    roles: ["Organisation Administrator", "Standard User", "Billing"],
  }),
  mounted() {
    this.init();
  },
  props: {
    org: Object,
    path: String,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    init() {
      this.loadUsers(this);
      if (this.user.roles.indexOf("System Administrator") >= 0) {
        this.roles.push("System Administrator");
        this.isSysUser = true;
      }
    },
    checkIfLastOrgAdmin(user) {
      if (user.email) {
        let matches = this.users.filter((user) => {
          return user.roles.indexOf("Organisation Administrator") > -1;
        });
        return (
          matches.length == 1 &&
          matches[0].email.toLowerCase() == user.email.toLowerCase()
        );
      }
    },
    loadUsers(me) {
      console.log(me);
      me.users = [];
      me.$db
        .collection("users")
        .where("organisationId", "==", this.org.id)
        .get()
        .then((results) => {
          results.docs.map((doc) => {
            let user = doc.data();
            user.id = doc.id;
            me.users.push(user);
          });
        });
        console.log(me.users);
    },
    sendSignInLinkToEmail() {
      this.$db
        .collection("users")
        .where("email", "==", this.email.toLowerCase())
        .get()
        .then((doc) => {
          if (doc.size > 0) {
            this.$store.dispatch("setToast", {
              message: "User already exists",
              color: "red darken-4",
            });
          } else {
            this.createUser();
          }
          console.log(doc);
        });
    },
    deleteItem() {
      confirm("Are you sure you want to delete this item?");
    },

    sendSignInLink(item) {
      console.log(item);
      if (item != undefined) {
        this.email = item.email;
        this.id = item.id;
      }
      var me = this;
      var encodedEmail = btoa(this.email);
      var baseUrl = process.env.VUE_APP_API_URL;
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: `${baseUrl}/signin-landing?orgID=${this.org.id}&e=${encodedEmail}`,
        // This must be true.
        handleCodeInApp: true,
      };
      var email = this.email;
      this.$firebase
        .auth()
        .sendSignInLinkToEmail(this.email, actionCodeSettings)
        .then(function () {
          me.$store.dispatch("setToast", {
            message: "Signin link sent to email : " + email,
            color: "green darken-4",
          });
          me.loadUsers(me);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    createUser() {
      var me = this;
      me.$db
        .collection("users")
        .add({
          email: this.email.toLowerCase(),
          status: "Invite link sent",
          organisationId: this.org.id,
          roles: ["Standard User"],
          isEnabled: true,
          createdOn: new Date(),
          modifiedOn: new Date(),
        })
        .then((doc) => {
          console.log(doc);
          me.sendSignInLink();
        })
        .catch((error) => {
          console.error("Error adding organsiation: ", error);
        });
    },
    saveUser() {
      this.$store.dispatch("saveUser", this.editedItem);
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      }
      this.close();
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      }, 300);
    },
    editUser(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
};
</script>
