import Vue from "vue";
import Vuex from "vuex";
import * as goalService from "../../services/goalService";

Vue.use(Vuex);

// initial state
const state = {
  currentGoal: null
};

// getters
const getters = {
  currentGoal(state) {
    return state.currentGoal;
  }
};

// actions
const actions = {
  setCurrentGoal({ commit }, payload) {
    goalService
      .save(payload, this.state.userModule.user)
      .then(ref => {
        if (ref != undefined) {
          payload.id = ref.id;
        }
        commit("setCurrentGoal", payload);
      })
      .catch(function(error) {
        console.error("Error saving organsiation: ", error);
      });
  }
};

// mutations
const mutations = {
  setCurrentGoal(state, payload) {
    state.currentGoal = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
