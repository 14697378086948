<template>
  <v-toolbar id="core-toolbar" flat class="d-print-none">
    <div class="v-toolbar-title">
      <v-toolbar-title class="tertiary--text font-weight-light">
        <v-btn v-if="responsive" class="default v-btn--simple" dark icon @click.stop="onClickBtn">
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
      </v-toolbar-title>
    </div>
    <breadcrumbs />
    <v-spacer />
    <router-link
      style="display:flex"
      v-if="user.firstName===undefined || user.firstName===null || user.lastName===undefined || user.lastName===null"
      tag="a"
      to="/user-profile"
    >
      <v-icon color="red">mdi-alert</v-icon>
    </router-link>
    <v-toolbar-items style="position:absolute; right:0">
      <template v-slot:activator="{ on }"></template>
      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" v-ripple class="toolbar-items" style="text-transform:none">
            <v-avatar size="30" v-if="user.photoURL">
              <v-img v-bind:src="user.photoURL"></v-img>
            </v-avatar>
            <v-icon color="tertiary" v-if="!user.photoURL">mdi-account</v-icon>
            <span class="toolbar-display-name">{{user.displayName}}</span>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="justify-center">
            <v-avatar slot="offset" class="mx-auto d-block" size="40" v-if="user.photoURL">
              <v-img v-bind:src="user.photoURL"></v-img>
            </v-avatar>
            <v-icon size="40" color="tertiary" v-if="user==null || !user.photoURL">mdi-account</v-icon>
          </v-card-title>
          <v-card-text>
            <h6 class="category text-gray font-weight-thin mb-3">{{user.email}}</h6>
            <h4 class="card-title font-weight-light">{{user.displayName}}</h4>
            <ul>
              <li>
                <router-link tag="a" to="/user-profile">My profile</router-link>
              </li>
              <li>
                <a @click="signOut">sign-out</a>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import breadcrumbs from "./Breadcrumb";
export default {
  components: {
    breadcrumbs
  },
  data: () => ({
    notifications: [
      "Mike, John responded to your email",
      "You have 5 new tasks",
      "You're now a friend with Andrew",
      "Another Notification",
      "Another One"
    ],
    title: null,
    responsive: false,
    responsiveInput: false,
    fav: true,
    menu: false,
    message: false,
    hints: true
  }),

  watch: {
    $route(val) {
      this.title = val.name;
    }
  },

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    onClickBtn() {
      this.$store.commit("setDrawer", !this.$store.state.appModule.drawer);
    },
    signOut() {
      this.$store.commit("reset");
      this.$router.push("/signin");
    },
    onResponsiveInverted() {
      if (window.innerWidth < 1025) {
        this.responsive = true;
        this.responsiveInput = false;
      } else {
        this.responsive = false;
        this.responsiveInput = true;
      }
    }
  }
};
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}
</style>
