import Vue from "vue";
import Vuex from "vuex";
import * as organisationService from "../../services/organisationService";

Vue.use(Vuex);

// initial state
const state = {
  currentOrganisation: null
};

// getters
const getters = {
  currentOrganisation(state) {
    return state.currentOrganisation;
  }
};

// actions
const actions = {
  setCurrentOrganisation({ commit }, payload) {
    organisationService
      .save(payload, this.state.userModule.user)
      .then(ref => {
        if (ref != undefined) {
          payload.id = ref.id;
        }
        commit("setCurrentOrganisation", payload);
      })
      .catch(function(error) {
        console.error("Error saving organsiation: ", error);
      });
  }
};

// mutations
const mutations = {
  setCurrentOrganisation(state, payload) {
    state.currentOrganisation = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
