 <template>
  <div>
    <v-menu
      ref="dateMenu"
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="dateString"
      transition="scale-transition"
      offset-y
      full-width
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <div>
          <v-btn fab small v-on="on" :disabled="disabled">
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
          {{moment(value).format("dddd DD MMMM YYYY")}}
        </div>
      </template>
      <v-date-picker
        v-model="dateString"
        no-title
        scrollable
        :disabled="disabled"
        color="green lighten-1"
        dark
        :min="min"
        :max="max"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dateMenu = false">Cancel</v-btn>
        <v-btn text color="primary" @click="$refs.dateMenu.save(dateString)">OK</v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>


<script>
import moment from "moment";
export default {
  data() {
    return {
      dateMenu: false,
      dateString: ""
    };
  },
  props: {
    label: String,
    vValidate: String,
    dataVvAs: String,
    hint: String,
    name: String,
    prependIcon: String,
    value: { required: false },
    min: String,
    max: String,
    solo: Boolean,
    format: String,
    disabled: Boolean
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    }
  },
  mounted() {
    this.dateString = this.value;
  },
  computed: {},

  watch: {
    dateString(newValue) {
      if (newValue !== null && newValue !== undefined) {
        this.dateString = newValue;
        this.$emit("input", newValue);
      }
    }
  }
};
</script>

<style>
</style>