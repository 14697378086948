import Vue from "vue";
import Vuex from "vuex";
import * as IncidentsService from "../../services/incidentsService";

Vue.use(Vuex);

// initial state
const state = {
  currentIncident: {}
};

// getters
const getters = {
  currentIncident(state) {
    return state.currentIncident == null ? {} : state.currentIncident;
  }
};

// actions
const actions = {
  setCurrentIncident({ commit }, payload) {
    IncidentsService.save(
      this.state.organisation.currentOrganisation.id,
      this.state.caseModule.currentCase.id,
      payload
    )
      .then(ref => {
        if (ref != undefined) {
          payload.id = ref.id;
        }
        commit("setCurrentIncident", payload);
      })
      .catch(function(error) {
        console.error("Error saving organsiation: ", error);
      });
  }
};

// mutations
const mutations = {
  setCurrentIncident(state, payload) {
    state.currentIncident = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
