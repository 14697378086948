<template>
  <div>
    <v-navigation-drawer
      id="app-drawer"
      v-model="inputValue"
      app
      dark
      floating
      persistent
      mobile-break-point="1025"
      width="260"
    >
      <v-img height="100%">
        <v-row class="fill-height" tag="v-list">
          <div class="close-drawer" style="position: absolute;
    
    right: 0;
    ">
            <v-icon size="14px" color="#26bfa3" @click="toggleDrawer">mdi-close-box-outline</v-icon>
          </div>
          <v-list-item>
            <v-list-item-avatar color="black">
              <img src="img/logo.png" height="40" />
            </v-list-item-avatar>
            <v-list-item-title class="title">OMAR</v-list-item-title>
          </v-list-item>

          <v-divider />

          <v-list-item
            v-for="(link, i) in authLinks"
            :key="i"
            :to="link.to"
            :active-class="color"
            class="v-list-item"
          >
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title v-text="link.text" />
          </v-list-item>
        </v-row>
      </v-img>
    </v-navigation-drawer>
  </div>
</template>

<script>
// Utilities
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    logo: "./img/logo.png",
    authLinks: [],
    links: [
      {
        to: "/dashboard",
        icon: "mdi-view-dashboard",
        text: "Dashboard"
      },
      {
        to: "/participant-management",
        icon: "mdi-briefcase-search-outline",
        text: "Participant management"
      },
      {
        to: "/roster",
        icon: "mdi-timetable",
        text: "Roster"
      },
      {
        to: "/timesheets",
        icon: "mdi-timetable",
        text: "Timesheets"
      },
      {
        to: "/invoicing-billing",
        icon: "mdi-currency-usd",
        text: "Invoicing and billing"
      },
      {
        to: "/policies-and-procedures",
        icon: "mdi-seal",
        text: "Policies and procedures"
      },
      {
        to: "/feedback",
        icon: "mdi-forum",
        text: "Feedback"
      },

      {
        to: "/admin",
        icon: "mdi-account-supervisor-circle",
        text: "Admin dashboard"
      },
      {
        to: "/system-admin",
        icon: "mdi-shield-key",
        text: "System admin"
      }
    ],
    responsive: false
  }),
  computed: {
    ...mapState(["image", "color"]),
    user() {
      return this.$store.getters.user;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentDrawer() {
      return this.$store.getters.drawer;
    },
    inputValue: {
      get() {
        return this.$store.state.appModule.drawer;
      },
      set(val) {
        this.$store.commit("setDrawer", val);
      }
    },
    items() {
      return this.$t("Layout.View.items");
    }
  },
  mounted() {
    this.init();
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  methods: {
    ...mapMutations(["toggleDrawer"]),

    init() {
      this.links.forEach(link => {
        const matchedRoute = this.$router.options.routes.find(route => {
          return route.path == link.to;
        });
        if (matchedRoute) {
          if (
            matchedRoute.meta != undefined &&
            matchedRoute.meta.roles != undefined
          ) {
            let match = false;
            this.user.roles.forEach(role => {
              if (matchedRoute.meta.roles.indexOf(role) > -1) {
                match = true;
              }
            });

            if (match) {
              this.authLinks.push(link);
            }
          } else {
            this.authLinks.push(link); //no roles on route so allow
          }
        }
      });
    },
    onResponsiveInverted() {
      if (window.innerWidth < 1025) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    }
  }
};
</script>

<style lang="scss">
#app-drawer {
  .v-list-item {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }

  .v-image__image--contain {
    top: 9px;
    height: 60%;
  }

  .search-input {
    margin-bottom: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
