export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function isEmpty(value) {
  return value === undefined || value === null;
}

export function emptyThen(value, substitute) {
  return isEmpty(value) ? substitute : value;
}

export function toFixed(value, decimals) {
  if (
    value !== undefined &&
    value !== null &&
    !isNaN(value) &&
    String(value).length > 0
  ) {
    return parseFloat(value).toFixed(decimals);
  }
}
export function hoursToHoursMinFormat(hrs) {
  const hoursPart = Math.floor(hrs) ;
  const minPart = Math.floor((parseFloat((hrs+"").indexOf(".")>=0?"."+(hrs+"").split(".")[1]:0)*60))+"" ;

  return hoursPart + ":" + (minPart.length==1? "0" :"") + minPart

}
export function toDt(value) {
  if (value !== undefined && value !== null) {
    if (typeof value.toDate !== "undefined") {
      return value.toDate();
    } else if (typeof value == "string") {
      return new Date(value);
    } else {
      return value;
    }
  }
}

export function multiFieldSort(){
  var _args = Array.prototype.slice.call(arguments);
    return function(a, b){
        for(var x in _args){
            var ax = a[_args[x].substring(1)];
            var bx = b[_args[x].substring(1)];
            var cx;

            ax = typeof ax == "string" ? ax.toLowerCase() : ax / 1;
            bx = typeof bx == "string" ? bx.toLowerCase() : bx / 1;

            if(_args[x].substring(0,1) == "-"){cx = ax; ax = bx; bx = cx;}
            if(ax != bx){return ax < bx ? -1 : 1;}
        }
    }
}

export function toAddress(value) {
  let intVal = "";
  if (value !== undefined) {
    if (value.description !== undefined && value.description.length > 0) {
      intVal = value.description;
    } else if (value.basic !== undefined && value.basic.length > 0) {
      intVal = value.basic;
    } else {
      if (typeof value == "string") {
        intVal = value;
      }
    }
  }
  return intVal;
}
