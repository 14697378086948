<template>
  <material-card v-bind="$attrs" class="v-card--material-stats" v-on="$listeners">
    <div class="text-xs-left icon">
      <v-icon size="40" :color="color">{{ icon }}</v-icon>
    </div>
    <div class="text-xs-right">
      <p class="font-weight-light" v-text="title" />
      <strong class="font-weight-light">
        {{ subText }}
        <small>{{ smallValue }}</small>
      </strong>
      <h3 class="title display-1 font-weight-light" :style="'color:' +valueColor">
        {{ value }}
        <small>{{ smallValue }}</small>
      </h3>
    </div>
  </material-card>
</template>

<script>
import Card from "./Card";

export default {
  inheritAttrs: false,

  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true
    },
    subIcon: {
      type: String,
      default: undefined
    },
    subIconColor: {
      type: String,
      default: undefined
    },
    subTextColor: {
      type: String,
      default: undefined
    },
    subText: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    },
    valueColor: {
      type: String,
      default: undefined
    },
    smallValue: {
      type: String,
      default: undefined
    }
  }
};
</script>

<style lang="scss">
.v-card--material-stats {
  display: flex;
  flex-wrap: wrap;
  min-height: 85px;
  position: relative;
  .icon {
    position: absolute;
  }
  .v-offset {
    display: inline-block;
    flex: 0 1;
    margin-top: 0;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0 !important;
    max-width: auto;
    padding: 0 16px 0;
  }

  .v-card {
    border-radius: 4px;
    flex: 0 1 auto;
  }

  .v-card__text {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }

  .v-card__actions {
    flex: 1 0 100%;
  }
}
</style>
