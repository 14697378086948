import Vue from "vue";

function getRef(orgId, caseId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("cases")
    .doc(caseId)
    .collection("goals");
}

export async function getById(orgId, caseId, id) {
  return getRef(orgId, caseId)
    .doc(id)
    .get();
}
export async function listRemovedByStatus(orgId, caseId, status) {
  return getRef(orgId, caseId)
    .where("isDeleted", "==", true)
    .where("status", "==", status)
    .get();
}
export async function remove(orgId, caseId, id, user) {
  return getRef(orgId, caseId)
    .doc(id)
    .update({ isDeleted: true, deletedBy: user, deletedOn: new Date() });
}
export async function unRemove(orgId, caseId, id) {
  return getRef(orgId, caseId)
    .doc(id)
    .update({ isDeleted: false, deletedBy: null, deletedOn: null });
}

export async function list(orgId, caseId) {
  return getRef(orgId, caseId)
    .where("isDeleted", "==", false)
    .get();
}
export async function listByStatus(orgId, caseId, status) {
  return getRef(orgId, caseId)
    .where("isDeleted", "==", false)
    .where("status", "==", status)
    .get();
}
export async function listbyBudgetID(orgId, caseId, bid)
{
  return getRef(orgId, caseId)
    .where("isDeleted", "==", false)
    .where("budgetItem.id", "==", bid)
    .get();
}

export async function save(orgId, caseId, payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef(orgId, caseId)
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef(orgId, caseId).add(obj);
  }
}
