import Vue from "vue";
import Vuex from "vuex";
import * as complaintService from "../../services/complaintService";
Vue.use(Vuex);

// initial state
const state = {
  count: 0,
  loading: false,
  toast: { message: "", color: "info", stamp: "" },
  error: null,
  showNav: true,
  identity: null,
  permission: null,
  drawer: null,
  color: "success",
  currentComplaint: {},
  currentFeedback: {},
  weekEndDate: new Date(),
};

// getters
const getters = {
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  toast(state) {
    return state.toast;
  },
  showNav(state) {
    return state.showNav;
  },
  identity(state) {
    return state.identity;
  },
  permission(state) {
    return state.permission;
  },
  currentComplaint(state) {
    return state.currentComplaint;
  },
  currentFeedback(state) {
    return state.currentFeedback;
  },
  weekEndDate(state) {
    return state.weekEndDate;
  }
};

// actions
const actions = {
  clearError({ commit }) {
    commit("clearError");
  },
  setLoading({ commit }, payload) {
    commit("setLoading", payload);
  },
  setToast({ commit }, payload) {
    commit("setToast", payload);
  },
  setShowNav({ commit }, payload) {
    commit("setShowNav", payload);
  },
  setIdentity({ commit }, payload) {
    commit("setIdentity", payload);
  },
  setPermission({ commit }, payload) {
    commit("setPermission", payload);
  },
  setWeekEndDate({ commit }, payload) {
    commit("setWeekEndDate", payload);
  },

  setCurrentComplaint({ commit }, payload) {
    if (payload.createdOn == undefined) {
      payload.createdOn = new Date();
    }
    payload.modifiedOn = new Date();
    complaintService
      .save(this.state.organisation.currentOrganisation.id, payload)
      .then(ref => {
        if (ref != undefined) {
          payload.id = ref.id;
        }
        commit("setCurrentComplaint", payload);
      })
      .catch(function(error) {
        console.error("Error saving organsiation: ", error);
      });
  },
  setCurrentFeedback({ commit }, payload) {
    commit("setCurrentFeedback", payload);
  }
};

// mutations
const mutations = {
  increment(state) {
    state.count++;
  },
  setDrawer(state, payload) {
    state.drawer = payload;
  },
  setImage(state, payload) {
    state.image = payload.image;
  },
  setColor(state, payload) {
    state.color = payload.color;
  },
  toggleDrawer(state, payload) {
    state.drawer = payload.drawer;
  },
  setWeekEndDate(state, payload) {
    state.weekEndDate = payload;
  },
  // setImage: set("image"),
  // setColor: set("color"),
  // toggleDrawer: toggle("drawer"),

  setLoading(state, payload) {
    state.loading = payload;
  },
  setToast(state, payload) {
    state.toast.message = payload.message;
    state.toast.color = payload.color;
    if (state.toast.stamp === undefined) {
      state.toast.stamp = 0;
    }
    state.toast.stamp++;
  },
  API_FAILURE(state, payload) {
    state.error = payload;
  },
  clearError(state) {
    state.error = null;
  },
  setShowNav(state, payload) {
    state.showNav = payload;
  },
  setIdentity(state, payload) {
    state.identity = payload;
  },
  setPermission(state, payload) {
    state.permission = payload;
  },
  setCurrentComplaint(state, payload) {
    state.currentComplaint = payload;
  },
  setCurrentFeedback(state, payload) {
    state.currentFeedback = payload;
  }
  
};

export default {
  state,
  getters,
  actions,
  mutations
};
