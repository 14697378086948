
<template>
  <v-app id="app">
    <core-toolbar v-if="user" />

    <core-drawer v-if="user" />

    <core-view />
    <v-snackbar
      :color="snackbarProps.color"
      :bottom="snackbarProps.bottom"
      :top="snackbarProps.top"
      :left="snackbarProps.left"
      :right="snackbarProps.right"
      v-model="snackbar"
      dark
    >
      <div>{{snackbarProps.text}}</div>
      <v-icon size="16" @click="snackbar = false">mdi-close-circle</v-icon>
    </v-snackbar>

    <div id="overlay" v-if="loading">
      <v-dialog v-model="loading" persistent width="200"  :hide-overlay="true">
        <v-card style="background-color: #156a59" dark>
          <v-card-text class="text-center" style="color: white">
            Loading
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import * as userService from "./services/userService";
import * as organisationService from "./services/organisationService";
export default {
  data() {
    return {
     
      snackbar: false,
      snackbarProps: {
        visible: false,
        color: "success",
        top: true,
        right: true,
        mode: "multi-line",
        timeout: 6000,
        text: ""
      }
    };
  },
  mounted() {
    const user = this.$store.getters.user;
    const org = this.$store.getters.currentOrganisation;
    if (user !== undefined && user !== null) {
      userService.registerUserListener(this.$store, user.id);
      organisationService.registerOrganisationListener(this.$store, org.id);
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    toast() {
      return this.$store.getters.toast.stamp;
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  watch: {
    loading(newValue) {
      if (newValue !== null && newValue !== undefined) {
        console.debug("loading value " + newValue);
      }
    },
    toast(newValue) {
      console.debug("toasting value " + newValue);
      //  if (newValue !== null && newValue !== undefined) {
      let value = this.$store.getters.toast;
      console.debug("toasting value " + newValue);
      this.snackbarProps.text = value.message;
      this.snackbarProps.color = value.color;
      this.snackbarProps.visible = true;
      this.snackbar = true;
      // }
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/index.scss";

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
