<template>
  <div>
    <div class="v-text-field__slot">
      <label class="v-label ml-8" style="font-size: 75%;">{{label}}</label>
    </div>

    <v-combobox
      :items="items"
      :loading="loading"
      :search-input.sync="search"
      autocomplete="off"
      item-text="description"
      name="location"
      placeholder="Start typing to search for an location "
      prepend-icon="mdi-map-marker"
      return-object
      auto-select-first
      @input="setLocation"
      clearable
      :value="intVal"
      v-validate="'required'"
      class="pt-0"
      :data-vv-as="dataVvAs"
      :error-messages="errors.collect('location')"
    ></v-combobox>
  </div>
</template>

<script>
import firebase from "@firebase/app";
import Vue from "vue";
import VeeValidate from "vee-validate";


Vue.use(VeeValidate);

export default {
  data: () => ({
    autocomplete: "",
    search: null,
    initialising: false,
    loading: false,
    valid: true,
    items: [],
    sessionToken: "",
    minChars: 3,
    form: {},
    intVal: "",
    lastname: ""
  }),
  props: {
    detailMode: Boolean,
    minCharacters: [Number, String],
    value: { required: false },
    label: String,
    required: [Boolean, String],
    name: String,
    vvalidate: String,
    dataVvAs: String,
    errorMessages: Array
  },
  mounted() {
    this.initialising = true;
    this.init();
    this.initialising = false;
  },
  computed: {},
  watch: {
    search(val) {
      if (val == undefined) {
        return;
      }
      if (
        !this.initialising &&
        this.value !== val &&
        (this.value == undefined || this.value.description == undefined
          ? ""
          : this.value.description) !== val
      ) {
        if (val != null && val.length >= this.minChars) {
          val && val !== this.select && this.querySelections(val);
        }
      }
    },
    value(val) {
      if (val !== null && val !== undefined) {
        this.init();
      }
    }
  },
  methods: {
    init() {
      if (
        this.minCharacters !== undefined &&
        this.minCharacters != null &&
        new String(this.minCharacters).length > 0
      ) {
        this.minChars = parseInt(new String(this.minCharacters));
      }
      if (this.value !== undefined) {
        if (
          this.value.description !== undefined &&
          this.value.description.length > 0
        ) {
          this.intVal = this.value.description;
        } else if (
          this.value.basic !== undefined &&
          this.value.basic.length > 0
        ) {
          this.intVal = this.value.basic;
        } else {
          if (typeof this.value == "string") {
            this.intVal = this.value;
          }
        }
      }
    },
    setLocation(location) {
      const me = this;

      if (location == undefined) {
        location = "";
        // location = {
        //   description: "",
        //   basic: ""
        // };
      }
      if (
        this.detailMode !== undefined &&
        this.detailMode != null &&
        this.detailMode === true
      ) {
        me.loading = true;
       
        var place = firebase.functions().httpsCallable("place");
        place({
          placeid: location.place_id,
          sessiontoken: me.sessionToken
        })
          .then(function(result) {
            // Read result of the Cloud Function.

            console.log(result);
            me.$emit("input", {
              description:
                location.description == undefined ? "" : location.description,
              basic: location,
              detailed: result.data.json.result
            });
            me.loading = false;

            // ...
          })
          .catch(error => {
            me.loading = false;
            console.log(error);
          })
          .finally(() => {
            me.loading = false;
          });
      } else {
        me.$emit("input", {
          description:
            location.description == undefined ? "" : location.description,
          basic: location
        });
      }
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    querySelections(text) {
      this.loading = true;
      // Simulated ajax query
      const me = this;
      me.items.splice(0, me.items.length);
      if (me.sessionToken.length == 0) {
        me.sessionToken = me.uuidv4();
      }
    
      // var placesAutoComplete = Vue.prototype.$functions.httpsCallable( "placesAutoComplete");
    
     
      var placesAutoComplete= firebase.functions().httpsCallable('placesAutoComplete');
      placesAutoComplete({
        input: text,
        sessiontoken: me.sessionToken,
        components: { country: "au" }
      }).then(function(result) {
          // Read result of the Cloud Function.
          console.log("reached here");
          console.log(result);
          result.data.json.predictions.map(data => {
            me.items.push(data);
          });
          me.loading = false;
          return me.items;
          // ...
        })
        
        .finally(() => {
          me.loading = false;
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

