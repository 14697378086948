 <template>
  <div>
    <v-menu
      ref="timeMenu"
      v-model="timeMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="timeString"
      transition="scale-transition"
      offset-y
      full-width
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="value"
          :label="label"
          :prepend-icon="prependIcon"
          :v-validate="vValidate"
          :data-vv-as="dataVvAs"
          placeholder="HH:MM"
          :name="name"
          :solo="solo"
          :disabled="disabled"
          style="max-width:80px"
          :error-messages="errors.collect(name)"
          readonly
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="timeMenu"
        :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55,60]"
        v-model="timeString"
        full-width
        :disabled="disabled"
        format="24hr"
        @click:minute="$refs.timeMenu.save(timeString)"
      ></v-time-picker>
    </v-menu>
  </div>
</template>


<script>
export default {
  data() {
    return {
      timeMenu: false,
      timeString: ""
    };
  },
  props: {
    label: String,
    vValidate: String,
    dataVvAs: String,
    solo: Boolean,
    name: String,
    prependIcon: String,
    disabled: Boolean,
    value: { required: false }
  },
  mounted() {
    this.timeString = this.value;
  },
  computed: {},
  methods: {
    selectFile() {
      this.$refs.uploadInput.click();
    }
  },
  watch: {
    timeString(newValue) {
      if (newValue !== null && newValue !== undefined) {
        this.timeString = newValue;
        this.$emit("input", newValue);
      }
    }
  }
};
</script>

<style>
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.uploader .v-btn {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
</style>