/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: "/dashboard",
    // Relative to /src/views
    view: "Dashboard",
    meta: {
      breadcrumb: [{ name: "Dashboard" }]
    }
  },
  {
    path: "/participant-management/reporting/alerts/alerts-expired-report",
    name: "Expired Alerts Report",
    view: "participant-management/reporting/alerts/alerts-expired-report",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Reports",
          link: "/participant-management/reporting/reports"
        },
        { name: "Alerts - Expired" }
      ]
    }
  },
  {
    path: "/participant-management/reporting/alerts/alerts-current-report",
    name: "Current Alerts Report",
    view: "participant-management/reporting/alerts/alerts-current-report",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Reports",
          link: "/participant-management/reporting/reports"
        },
        { name: "Alerts - Current" }
      ]
    }
  },
  {
    path: "/participant-management/reporting/alerts/alerts-all-report",
    name: "All Alerts Report",
    view: "participant-management/reporting/alerts/alerts-all-report",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Reports",
          link: "/participant-management/reporting/reports"
        },
        { name: "Alerts - All" }
      ]
    }
  },
  {
    path: "/participant-management/reporting/reports",
    name: "Reports",
    view: "participant-management/reporting/reports",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Reports" }
      ]
    }
  },
  {
    path: "/403",
    name: "Access denied",
    view: "403"
  },
  {
    path: "/user-profile",
    name: "User profile",
    view: "UserProfile"
  },
  {
    path: "/main-form",
    name: "Main-Form",
    view: "participant-management/forms/main-form",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Participant form" }
      ]
    }
  },

  {
    path: "/individual-risk-assessments",
    name: "Individual Risk Assessment",
    view: "participant-management/individual-risk-assesment/main-form",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Individual Risk Assessments" }
      ]
    }
  },
  {
    path: "/individual-risk-assessments/assessment",
    name: "Risk Assessment",
    view: "participant-management/individual-risk-assesment/assessment",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Individual Risk Assessments",
          link: "/individual-risk-assessments"
        },
        { name: "Assessment" }
      ]
    }
  },
  {
    path: "/system-admin/organisations/details",
    name: "Organisations",
    view: "system-admin/organisation",
    meta: {
      breadcrumb: [
        { name: "System admin", link: "/system-admin/dashboard" },
        {
          name: "Organisations management",
          link: "/system-admin/organisations"
        },
        {
          name: "Organisations details"
        }
      ],
      roles: ["System Administrator"]
    }
  },

  {
    path: "/participant-management/reporting/general-notes/notes-report",
    name: "General Notes",
    view: "participant-management/reporting/general-notes/notes-report",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Reports",
          link: "/participant-management/reporting/reports"
        },
        { name: "General Notes" }
      ]
    }
  },
  {
    path:
      "/participant-management/reporting/goals-progress/goal-summary/goal-summary",
    name: "goal-summary",
    view:
      "participant-management/reporting/goals-progress/goal-summary/goal-summary",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Reports",
          link: "/participant-management/reporting/reports"
        },
        { name: "Goal - Summary" }
      ]
    }
  },
  {
    path:
      "/participant-management/reporting/participant-details/fullreport/participant-details",
    name: "participant-details",
    view:
      "participant-management/reporting/participant-details/fullreport/participant-details",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Reports",
          link: "/participant-management/reporting/reports"
        },
        { name: "Participant Details Report" }
      ]
    }
  },
  {
    path: "/participant-management",
    name: "Participant management",
    view: "participant-management/dashboard",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        {
          name: "Participant management"
        }
      ]
    }
  },
  {
    path: "/participant-management/search",
    name: "Participant management - search",
    view: "participant-management/search",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        {
          name: "Participant management",
          link: "/participant-management"
        },
        { name: "Search" }
      ]
    }
  },
  {
    path: "/participant-management/current-participant",
    name: "Current Participant",
    view: "participant-management/current-participant",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        {
          name: "Participant management",
          link: "/participant-management"
        },
        { name: "participant" }
      ]
    }
  },
  {
    path: "/participant-management/documents",
    name: "documents",
    view: "participant-management/documents",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Additional Documents" }
      ]
    }
  },
  {
    path: "/goals",
    name: "Goals and progress notes",
    view: "participant-management/goals/goals",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Goals and progress notes" }
      ]
    }
  },
  {
    path: "/goal-details",
    name: "Goals Details",
    view: "participant-management/goals/goal-details",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Goals and progress notes", link: "/goals" },
        { name: "Goal details" }
      ]
    }
  },

  {
    path: "/progress-notes",
    name: "notes",
    view: "participant-management/goals/notes-form",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Goals and progress notes", link: "/goals" },
        { name: "Goals details", link: "/goal-details" },
        { name: "Progress notes" }
      ]
    }
  },

 
  {
    path: "/invoicing-billing/notes",
    name: "notes",
    view: "participant-management/goals/notes-form",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Invoicing and billing" , link: "/invoicing-billing" },
        { name: "progress notes" }
      ]
    }
  },
  
  {
    path: "/test",
    name: "test",
    view: "test"
  },
  {
    path: "/alerts",
    name: "Alerts",
    view: "participant-management/alerts/alerts",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Alerts" }
      ]
    }
  },
  {
    path: "/general-notes",
    name: "General Notes",
    view: "participant-management/notes/notes-list",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "General notes" }
      ]
    }
  },
  {
    path: "/participant-management/alerts",
    name: "Alert Details",
    view: "participant-management/alerts/alerts",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Alerts" }
      ]
    }
  },

  {
    path: "/participant-management/current-participant/videos",
    name: "Videos",
    view: "participant-management/video",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Videos" }
      ]
    }
  },
  {
    path: "/participant-management/current-participant/about-me",
    name: "About Me",
    view: "participant-management/about-me",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "About me" }
      ]
    }
  },
  {
    path: "/participant-management/current-participant/invoicing-billing",
    name: "Invoicing billing",
    view: "participant-management/invoicing-billing/index",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        { name: "Invoicing billing" }
      ]
    }
  },
  {
    path: "/participant-management/current-participant/budget-Items",
    name: "Budget items",
    view: "participant-management/invoicing-billing/budget-items",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Invoicing billing",
          link: "/participant-management/current-participant/invoicing-billing"
        },
        { name: "Budget items" }
      ]
    }
  },
  {
    path:
      "/participant-management/current-participant/invoicing-billing/additional-export-data",
    name: "Budget items",
    view: "participant-management/invoicing-billing/additional-export-data",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Invoicing billing",
          link: "/participant-management/current-participant/invoicing-billing"
        },
        { name: "Additional export data" }
      ]
    }
  },
  {
    path: "/participant-management/current-participant/budget-Items/details",
    name: "Budget item details",
    view: "participant-management/invoicing-billing/budget-item-details",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Invoicing billing",
          link: "/participant-management/current-participant/invoicing-billing"
        },
        {
          name: "Budget Items",
          link: "/participant-management/current-participant/budget-Items"
        },
        { name: "Details" }
      ]
    }
  },

  {
    path: "/signin",
    name: "Signin",
    view: "signin"
  },
  {
    path: "/signin-landing",
    name: "Signin landing",
    view: "signin-landing"
  },
  {
    path: "/reset-password",
    name: "Reset password",
    view: "reset-password"
  },

  {
    path: "/participant-management/notes",
    name: "Progress notes",
    view: "participant-management/notes-list"
  },

  {
    path: "/feedback",
    name: "Feedback",
    view: "feedback/feedback-dashboard",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Feedback" }
      ]
    }
  },
  {
    path: "/feedback/general-feedback",
    name: "General feedback",
    view: "feedback/feedback",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Feedback", link: "/feedback" },
        { name: "General feedback" }
      ]
    }
  },
  {
    path: "/feedback/feedback-details",
    name: "Add/Edit General feedback",
    view: "feedback/feedback-form",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Feedback", link: "/feedback" },
        { name: "General feedback", link: "/feedback/general-feedback" },
        { name: "General feedback details" }
      ]
    }
  },

  {
    path: "/feedback/complaint",
    name: "Feedback complaint",
    view: "feedback/complaint-form",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Feedback", link: "/feedback" },
        { name: "Complaints Overview", link: "/feedback/complaints-overview" },
        { name: "Complaint" }
      ]
    }
  },
  {
    path: "/feedback/complaints-overview",
    name: "ComplaintsOverview",
    view: "feedback/complaints-overview",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Feedback", link: "/feedback" },
        { name: "Complaints Overview" }
      ]
    }
  },
  {
    path: "/policies-and-procedures",
    name: "ComplaintsOverview",
    view: "policies/policies-and-procedures",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Policies and procedures" }
      ],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },

  {
    path: "/participant-management/incidents/incidents",
    name: "Incidents",
    view: "participant-management/incidents/incidents",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Incidents",
          link: ""
        }
      ]
    }
  },
  {
    path: "/participant-management/incidents/main-form",
    name: "Incidents - Immediate",
    view: "participant-management/incidents/main-form",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Incidents",
          link: "/participant-management/incidents/incidents"
        },
        {
          name: "Adding Incident",
          link: ""
        }
      ]
    }
  },
  {
    path: "/subjects-of-allegation-add",
    name: "Add Subjects of allegation",
    view: "participant-management/incidents/subjects-of-allegation-add",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Participant management", link: "/participant-management" },
        {
          name: "participant",
          link: "/participant-management/current-participant"
        },
        {
          name: "Incidents",
          link: "/participant-management/incidents/incidents"
        },
        {
          name: "Adding Subjects to Incident",
          link: ""
        }
      ]
    }
  },
  {
    path: "/service-agreement-form",
    name: "Service agreement form",
    view: "service-agreement-form",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Service agreement" }
      ]
    }
  },

  {
    path: "/address",
    name: "address",
    view: "AddressWrapper"
  },

  {
    path: "/admin",
    name: "Admin",
    view: "admin-dashboard",
    meta: {
      breadcrumb: [{ name: "Admin" }],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },
  {
    path: "/form-success",
    name: "Form complete",
    view: "participant-management/forms/form-success"
  },
  {
    path: "/roster",
    name: "Roster",
    view: "roster/roster",
    meta: {
      breadcrumb: [{ name: "Dashboard", link: "dashboard" }, { name: "Roster" }]
    }
  },
  {
    path: "/roster/event",
    name: "Roster-event",
    view: "roster/event",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Roster", link: "/roster" },
        { name: "Event" }
      ]
    }
  },
  
  {
    path: "/timesheets",
    name: "Timesheets dashboard",
    view: "timesheets/dashboard",
    meta: {
      breadcrumb: [
        { name: "Timesheets dashboard", link: "dashboard" },
        { name: "Timesheets" }
      ]
    }
  },
  {
    path: "/timesheets/review",
    name: "Timesheets",
    view: "timesheets/timesheet-list",
    meta: {
      breadcrumb: [
        { name: "Timesheets dashboard", link: "/timesheets" },
        { name: "Timesheets review" }
      ],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },
  {
    path: "/timesheets/report",
    name: "Timesheets",
    view: "timesheets/timesheet-report",
    meta: {
      breadcrumb: [
        { name: "Timesheets dashboard", link: "/timesheets" },
        { name: "Timesheets report" }
      ],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },
  {
    path: "/timesheets/my-timesheet-hours-report",
    name: "Timesheets",
    view: "timesheets/timesheet-report-by-user",
    meta: {
      breadcrumb: [
        { name: "Timesheets dashboard", link: "/timesheets" },
        { name: "My timesheet report" }
      ],
    }
  },
  {
    path: "/timesheets/review/timesheet",
    name: "Timesheet",
    view: "timesheets/timesheet",
    meta: {
      breadcrumb: [
        { name: "Timesheets dashboard", link: "/timesheets" },
        { name: "Timesheets review", link: "/timesheets/review" },
        { name: "Timesheet" }
      ]
    }
  },
  {
    path: "/my-timesheet",
    name: "my-timesheet",
    view: "timesheets/timesheet",
    meta: {
      breadcrumb: [
        { name: "Timesheets dashboard", link: "/timesheets" },
        { name: "My Timesheet" }
      ]
    }
  },
  {
    path: "/invoicing-billing",
    name: "Invoicing and billing",
    view: "finance/batch-list",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Invoicing and billing" }
      ],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },
  {
    path: "/invoicing-billing/batch",
    name: "batch",
    view: "finance/batch",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Invoicing and billing" , link: "/invoicing-billing" },
        { name: "Batch" }
      ],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },
  {
    path: "/invoicing-billing/batch/add-items",
    name: "Add Items",
    view: "finance/search-notes",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "Invoicing and billing" , link: "/invoicing-billing" },
        { name: "Batch" , link: "/invoicing-billing/batch" },
        { name: "Add items" }
      ],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },
  {
    path: "/admin/organisation-details",
    name: "organisation-details",
    view: "user-management/organisation",
    meta: {
      breadcrumb: [
        { name: "Admin", link: "/admin" },
        { name: "Organisation details" }
      ],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },
  {
    path: "/admin/organisation-data",
    name: "Organisation data",
    view: "system-admin/organisation-data",
    meta: {
      breadcrumb: [
        { name: "Admin", link: "/admin" },
        { name: "Organisation data" }
      ],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },
  {
    path: "/admin/defaults",
    name: "Organisation defaults",
    view: "system-admin/organisation-defaults",
    meta: {
      breadcrumb: [
        { name: "Admin", link: "/admin" },
        { name: "System defaults" }
      ],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },
  {
    path: "/admin/export-data",
    name: "Export Data",
    view: "system-admin/export-data",
    meta: {
      breadcrumb: [{ name: "Admin", link: "/admin" }, { name: "Export data" }],
      roles: ["System Administrator", "Organisation Administrator"]
    }
  },
  {
    path: "/admin/notifications-data",
    name: "Notifications",
    view: "system-admin/notifications-data",
    meta: {
        breadcrumb: [{ name: "Admin", link: "/admin" }, { name: "Notifications" }],
        roles: ["System Administrator", "Organisation Administrator"]
    }
  },

  /**********************************************
   * //System Admins only
   **********************************************/
  {
    path: "/system-admin",
    name: "System Admin",
    view: "system-admin/dashboard",
    meta: {
      breadcrumb: [
        { name: "Dashboard", link: "dashboard" },
        { name: "System admin" }
      ],
      roles: ["System Administrator"]
    }
  },
  {
    path: "/system-admin/organisations",
    name: "organisations",
    view: "system-admin/organisation-list",
    meta: {
      breadcrumb: [
        { name: "System admin", link: "/system-admin/dashboard" },
        {
          name: "Organisations management"
        }
      ],
      roles: ["System Administrator"]
    }
  },
  {
    path: "/system-admin/line-items",
    name: "Line items",
    view: "system-admin/lineItems",
    meta: {
      breadcrumb: [
        { name: "System admin", link: "/system-admin/dashboard" },
        {
          name: "Line items"
        }
      ],
      roles: ["System Administrator"]
    }
  },
  {
    path: "/system-admin/line-items-update",
    name: "Line items",
    view: "system-admin/lineItemsUpdate",
    meta: {
      breadcrumb: [
        { name: "System admin", link: "/system-admin/dashboard" },
        {
          name: "Line items update"
        }
      ],
      roles: ["System Administrator"]
    }
  }
  
  /**********************************************
   **********************************************/
];
