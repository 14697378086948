import Vue from "vue";

// Components
import "./components";

// Plugins
import "./plugins";
import vuetify from "./plugins/vuetify";
//import primevue from "./plugins/primevue";
// Sync router with store
import { sync } from "vuex-router-sync";

// Application imports
import App from "./App";
import i18n from "@/i18n";
import router from "@/router";
import store from "@/store";
import { firestorePlugin } from "vuefire";

import firebase from "@firebase/app";
//import firebaseX from "firebase";
import "@firebase/functions";
import "@firebase/auth";
import "@firebase/firestore";
import Fingerprint2 from "fingerprintjs2";
import * as utilsService from "./services/utilsService";

// Sync store with router
sync(store, router);
Vue.use(firestorePlugin);

// Initialize Firebase

const firebaseConfig = {
 
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId

 /*   apiKey: "AIzaSyDu_ydqnDFKkhi9XiXFuWeo_nTYV1hqGGU",
    authDomain: "omar-prod-239311.firebaseapp.com",
    databaseURL: "https://omar-prod-239311.firebaseio.com",
    projectId: "omar-prod-239311",
    storageBucket: "omar-prod-239311.appspot.com",
    messagingSenderId: "464893207162"*/
}


firebase.initializeApp(firebaseConfig);


console.log(process.env);
if (window.requestIdleCallback) {
  requestIdleCallback(() => {
    Fingerprint2.get(components => {
      var values = components.map(component => {
        return component.value;
      });
      var murmur = Fingerprint2.x64hash128(values.join(""), 31);
      document.cookie = "murmur=" + murmur;
    });
  });
} else {
  setTimeout(function() {
    Fingerprint2.get(function(components) {
      var values = components.map(component => {
        return component.value;
      });
      var murmur = Fingerprint2.x64hash128(values.join(""), 31);
      document.cookie = "murmur=" + murmur;
    });
  }, 500);
}
Vue.config.productionTip = false;
 const db = firebase.firestore();
 const functions = firebase.functions();
 const fieldValue=firebase.firestore.FieldValue;

export { db, functions, fieldValue};

if (process.env.NODE_ENV == "development") {
  functions.useFunctionsEmulator("http://localhost:5000");
  console.log("emulating functions url http://localhost:5000");
}



db.enablePersistence().catch(function(err) {
  if (err.code == "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code == "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});
/* eslint-disable no-new */

Object.defineProperty(Vue.prototype, "$db", { value: db });
Object.defineProperty(Vue.prototype, "$functions", { value: functions });
Object.defineProperty(Vue.prototype, "$firebase", { value: firebase });
Object.defineProperty(Vue.prototype, "$fingerPrint", { value: Fingerprint2 });
Object.defineProperty(Vue.prototype, "$utils", { value: utilsService });


new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
