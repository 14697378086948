import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// initial state
const state = {
  currentBatch: null
};

// getters
const getters = {
  currentBatch(state) {
    return state.currentBatch;
  }
};

// actions
const actions = {
  setCurrentBatch({ commit }, payload) {
    commit("setCurrentBatch", payload);
  }
};

// mutations
const mutations = {
  setCurrentBatch(state, payload) {
    state.currentBatch = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
