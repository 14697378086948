import Vue from "vue";
import Vuex from "vuex";
import * as progressNotesService from "../../services/progressNotesService";

Vue.use(Vuex);

// initial state
const state = {
  currentProgressNote: null
};

// getters
const getters = {
  currentProgressNote(state) {
    return state.currentProgressNote;
  }
};

// actions
const actions = {
  setCurrentProgressNote({ commit }, payload) {
    progressNotesService
      .save(payload, this.state.userModule.user)
      .then(ref => {
        if (ref != undefined) {
          payload.id = ref.id;
        }
        commit("setCurrentProgressNote", payload);
      })
      .catch(function(error) {
        console.error("Error saving organsiation: ", error);
      });
  }
};

// mutations
const mutations = {
  setCurrentProgressNote(state, payload) {
    state.currentProgressNote = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
