<template>
  <v-container class="py-0">
    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          label="location"
          v-model="location"
          name="location"
          prepend-icon="mdi-map-marker"
          placeholder="Start typing to search for an location "
          :error-messages="errors.collect('location')"
        />
        <ul>
        <li v-for="(result, i) in searchResults" :key="i">
          {{ result }} // list of all places
        </li>
    </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    location: "",
    searchResults: [],
    service: null, // will reveal this later 🕵️
  }),
  metaInfo() {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDnj-g-3hFf-e8YmK81vXQZt0pq2F5PX2o&libraries=places`,
          async: true,
          defer: true,
          callback: () => this.MapsInit(), // will declare it in methods
        },
      ],
    };
  },
  methods: {
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService();
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
      }
      this.searchResults = predictions.map((prediction) => prediction.description);
    },
  },
  watch: {
    location(newValue) {
      if (newValue) {
        this.service.getPlacePredictions(
          {
            input: this.location,
            types: ["(cities)"],
          },
          this.displaySuggestions
        );
      }
    },
  },
};
</script>
