import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import { db } from "../../main";

// initial state
const state = {
  user: null,
  userExpiry: new Date(),
  lastUpdateUser: null,
  listener: null
};

// getters
const getters = {
  user(state) {
    return state.user;
  },
  userExpiry(state) {
    return state.userExpiry;
  },
  listener(state) {
    return state.listener;
  }
};

// actions
const actions = {
  saveUser({ commit }, payload) {
    const obj = Object.assign({}, payload);
  console.log(obj);
    if (obj.id != undefined) {
      obj.modifiedOn = new Date();
      db.collection("users")
        .doc(obj.id)
        .update(obj)
        .then(() => {
          commit("setLastUpdateUser", obj);
        })
        .catch(function(error) {
          console.error("Error editing user: ", error);
        });
    }
  }
};

// mutations
const mutations = {
  setUser(state, payload) {
    if (payload === undefined || payload === null) {
      if (state.listener != null) {
        state.listener();
      }
    }
    if (payload !== undefined && payload !== null && payload.isEnabled) {
      state.user = payload;
    } else {
      state.user = null;
    }
  },
  setLastUpdateUser(state, payload) {
    state.lastUpdateUser = payload;
  },
  setListener(state, payload) {
    state.listener = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
