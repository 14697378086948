import Vue from "vue";
function getRef() {
  return Vue.prototype.$db.collection("users");
}

export async function getById(id) {
  return getRef()
    .doc(id)
    .get();
}

export async function getByOrgId(orgId)
{
  return getRef()
  .where("organisationId","==",orgId)
  .get();
}

export async function save(payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef()
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef().add(obj);
  }
}

export async function list() {
  return getRef().get();
}

export function registerUserListener(userStore, id) {
  var listener = Vue.prototype.$db
    .collection("users")
    .doc(id)
    .onSnapshot(doc => {
      // var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
      // console.log(source, " data: ", doc.data());
      if (userStore.getters.user != null) {
        userStore.commit("setUser", doc.data());
      }
      userStore.commit("setListener", listener);
    });
}
