// Lib imports
import Vue from "vue";
import VueAnalytics from "vue-analytics";
import Router from "vue-router";
import Meta from "vue-meta";
import store from "../store/index";
import * as utilsService from "../services/utilsService";
// Routes
import paths from "./paths";

function route(path, view, name, meta) {
  return {
    name: name || view,
    path,
    component: resovle => import(`@/views/${view}.vue`).then(resovle),
    meta
  };
}

Vue.use(Router);
Vue.use(Meta);
var mystore = store;

// Create a new router
const router = new Router({
  mode: "history",
  routes: paths
    .map(path => route(path.path, path.view, path.name, path.meta))
    .concat([{ path: "*", redirect: "/dashboard" }]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (
    to.name === "Signin" ||
    to.name === "Signin landing" ||
    to.name === "Reset password"
  ) {
    next();
  } else {
    if (mystore.getters.user) {
      let user = mystore.getters.user;
      let organisation = mystore.getters.currentOrganisation;
      let murmur = utilsService.getCookie("murmur");

      if (
        user.isEnabled === false ||
        (organisation !== null &&
          organisation.isEnabled === false &&
          user.roles.indexOf("System Administrator") === -1) ||
        murmur !== user.murmur
      ) {
        mystore.commit("setUser", null);
        next("/403"); // go to '/login';
      }

      if (
        from.name == "Signin" &&
        to.name !== "User profile" &&
        (utilsService.isEmpty(user.firstName) ||
          utilsService.isEmpty(user.lastName))
      ) {
        next("/user-profile");
      }

      if (to.meta.roles != undefined) {
        let match = false;
        to.meta.roles.forEach(role => {
          if (user.roles.indexOf(role) > -1) {
            match = true;
          }
        });
        if (match) {
          next(); // allow to enter route
        } else {
          next("/403"); // go to '/login';
        }
      } else {
        next();
      }
      // or however you store your logged in state
    } else {
      next("/signin"); // go to '/login';
    }
  }
});

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.GOOGLE_ANALYTICS) {
  Vue.use(VueAnalytics, {
    id: process.env.GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== "development"
    }
  });
}

export default router;
