import Vue from "vue";
import Vuex from "vuex";
import * as EventsService from "../../services/eventService";

Vue.use(Vuex);

// initial state
const state = {
  currentEvent: {},
  rosterView: "calendar",
  calendarView: "dayGridMonth",
  currentTimesheet: {},
  currentDate: new Date(),
  filters: { guests: [], programs: [] }
};

// getters
const getters = {
  currentEvent(state) {
    return state.currentEvent;
  },
  currentTimesheet(state) {
    return state.currentTimesheet;
  },
  filters(state) {
    return state.filters;
  },
  rosterView(state) {
    return state.rosterView;
  },
  calendarView(state) {
    return state.calendarView;
  },
  currentDate(state) {
    return state.currentDate;
  }
};

// actions
const actions = {
  setCurrentEvent({ commit }, payload) {
    EventsService.save(this.state.organisation.currentOrganisation.id, payload)
      .then(ref => {
        if (ref != undefined) {
          payload.id = ref.id;
        }
        commit("setCurrentEvent", payload);
      })
      .catch(function(error) {
        console.error("Error saving organsiation: ", error);
      });
  },
  setCurrentTimesheet({ commit }, payload) {
    commit("setCurrentTimesheet", payload);
  },
  setFilters({ commit }, payload) {
    commit("setFilters", payload);
  },
  setRosterView({ commit }, payload) {
    commit("setRosterView", payload);
  },
  setCalendarView({ commit }, payload) {
    commit("setCalendarView", payload);
  },
  setCurrentDate({ commit }, payload) {
    commit("setCurrentDate", payload);
  }
};

// mutations
const mutations = {
  setCurrentEvent(state, payload) {
    state.currentEvent = payload;
  },
  setCurrentTimesheet(state, payload) {
    state.currentTimesheet = payload;
  },
  setFilters(state, payload) {
    state.filters = payload;
  },
  setRosterView(state, payload) {
    state.rosterView = payload;
  },
  setCalendarView(state, payload) {
    state.calendarView = payload;
  },
  setCurrentDate(state, payload) {
    state.currentDate = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
