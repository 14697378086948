export class UserSummary {
    constructor(user) {
        this.id =  ((user.id!==undefined && user.id!==null) ? user.id:"");
        this.organisationId = ((user.organisationId!==undefined && user.organisationId!==null) ? user.organisationId:"");
        this.displayName = ((user.displayName!==undefined && user.organidisplayNameationId!==null) ? user.displayName:""); 
        this.firstName = ((user.firstName!==undefined && user.firstName!==null) ? user.firstName:""); 
        this.lastName = ((user.lastName!==undefined && user.lastName!==null) ? user.lastName:""); 
        this.email =((user.email!==undefined && user.email!==null) ? user.email:"");  
          this.photoURL = ((user.photoURL !== undefined && user.photoURL !== null) ? user.photoURL : ""); 
          if ((this.displayName===undefined || this.displayName ===null ||  this.displayName.length === 0)  && this.firstName.length>0) { 
              this.displayName = this.firstName + " " + this.lastName
          }
      }
   
  }