<template>
  <v-menu
    :close-on-content-click="false"
    bottom
    left
    min-width="300"
    max-width="300"
    nudge-left="12"
    offset-x
    transition="slide-y-transition"
  >
    <v-btn slot="activator" class="elevation-0" color="grey" dark fab fixed style="top: 96px;" top>
      <v-icon>mdi-settings</v-icon>
    </v-btn>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center body-2 text-uppercase sidebar-filter">Sidebar Filters</div>

            <v-row justify="center">
              <v-avatar
                v-for="c in colors"
                :key="c"
                :class="[c === color ? 'color-active color-' + c: 'color-' + c]"
                size="23"
                @click="setColor(c)"
              />
            </v-row>
            <v-divider class="mt-3" />
          </v-col>
          <v-col cols="12">
            <div class="text-center body-2 text-uppercase sidebar-filter">Images</div>
          </v-col>

          <v-col cols="12">
            <v-btn
              href="https://www.creative-tim.com/product/vuetify-material-dashboard"
              target="_blank"
              color="success"
              block
            >Free Download</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              href="https://demos.creative-tim.com/vuetify-material-dashboard/documentation"
              target="_blank"
              class="white--text"
              color="primary"
              block
            >Documentation</v-btn>
          </v-col>
          <v-col cols="12">
            <div class="text-center body-2 text-uppercase">
              <div class="sidebar-filter">Thank You for Sharing!</div>

              <div>
                <v-btn color="indigo" class="mr-2 v-btn-facebook" fab icon small round>
                  <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <v-btn color="cyan" class="v-btn-twitter" fab icon small round>
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
// Utilities
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    colors: ["primary", "info", "success", "warning", "danger"]
  }),

  computed: {
    ...mapState(["image", "color"]),
    color() {
      return this.$store.state.color;
    }
  },

  methods: {
    ...mapMutations(["setImage"]),
    setColor(color) {
      this.$store.state.color = color;
    }
  }
};
</script>

<style lang="scss">
.v-avatar,
.v-responsive {
  cursor: pointer;
}
</style>
