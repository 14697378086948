import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import { db } from "../../main";

// initial state
const state = {
  currentCase: { contacts: [] },
  currentVideoSet: {},
  currentBudgetItem: {},
  currentAssessment: {},
  currentRisk: {},
  cases: [],
  lastSearchTerm:"",
  recentCases:[]
};

// getters
const getters = {
  currentCase(state) {
    return state.currentCase;
  },
  lastSearchTerm(state) {
    return state.lastSearchTerm;
  },
  cases(state) {
    return state.cases;
  },
  recentCases(state) {
    return state.recentCases;
  },
  currentVideoSet(state) {
    return state.currentVideoSet;
  },
  currentBudgetItem(state) {
    return state.currentBudgetItem;
  },
  currentAssessment(state) {
    return state.currentAssessment;
  },
  currentRisk(state) {
    return state.currentRisk;
  }
};

// actions
const actions = {
  addVideoSet({ commit }, payload) {
    const obj = Object.assign({}, payload);

    if (payload.id != undefined) {
      // To update age and favorite color:
      db.collection("organisations")
        .doc(this.state.organisation.currentOrganisation.id)
        .collection("cases")
        .doc(this.state.caseModule.currentCase.id)
        .collection("videos")
        .doc(payload.id)
        .update(obj)
        .then(() => {
          commit("setCurrentVideo", payload);
        })
        .catch(function(error) {
          console.error("Error editing document: ", error);
        });
    } else {
      db.collection("organisations")
        .doc(this.state.organisation.currentOrganisation.id)
        .collection("cases")
        .doc(this.state.caseModule.currentCase.id)
        .collection("videos")
        .add(obj)
        .then(docRef => {
          payload.id = docRef.id;
          commit("setCurrentVideo", payload);
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    }
  },
  setCurrentCase({ commit }, payload) {
    let organisation = this.state.organisation.currentOrganisation;
    var json = JSON.parse(JSON.stringify(payload));
    if (payload.status == undefined || payload.status == null) {
      payload.status = "Draft";
      payload.createdOn = new Date();
      payload.createdBy = this.state.userModule.user;
    }
    if(payload.isDeleted===undefined){
      payload.isDeleted = false;
    }
    payload.modifiedBy = this.state.userModule.user;
    payload.ModifiedOn = new Date();
    if (payload.id != undefined) {
      // To update age and favorite color:
      db.collection("organisations")
        .doc(organisation.id)
        .collection("cases")
        .doc(payload.id)
        .update(json)
        .then(() => {
          commit("setCurrentCase", payload);
        })
        .catch(function(error) {
          console.error("Error editing document: ", error);
        });
    } else {
      db.collection("organisations")
        .doc(organisation.id)
        .collection("cases")
        .add(json)
        .then(docRef => {
          payload.id = docRef.id;
          commit("setCurrentCase", payload);
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    }
  },
  setCases({ commit }, payload) {

    commit("setCases", payload);
  },
  setRecentCases({ commit }, payload) {
    commit("setRecentCases", payload);
  },
  setLastSearchTerm({ commit }, payload) {
    commit("setLastSearchTerm", payload);
  },
  setCurrentBudgetItem({ commit }, payload) {
    commit("setCurrentBudgetItem", payload);
  },
  setCurrentAssessment({ commit }, payload) {
    commit("setCurrentAssessment", payload);
  },
  setCurrentRisk({ commit }, payload) {
    commit("setCurrentRisk", payload);
  },
  setCurrentVideo({ commit }, payload) {
    commit("setCurrentVideo", payload);
  }
};

// mutations
const mutations = {
  setCurrentCase(state, payload) {
    state.currentCase = payload;
  },
  setCases(state, payload) {
    state.cases = payload;
  },
  setLastSearchTerm(state, payload) {
    state.lastSearchTerm = payload;
  },
  
  setRecentCases(state, payload) {    
    const match = state.recentCases.find(x=> x.id==payload.id)
    if(match!==undefined){
      state.recentCases.splice(state.recentCases.indexOf(match),1)
    }
    state.recentCases.unshift(payload)
    if(state.recentCases.length>10){
      state.recentCases.splice(10,state.recentCases.length);
    }
  },
  setCurrentVideo(state, payload) {
    state.currentVideoSet = payload;
  },
  setCurrentBudgetItem(state, payload) {
    state.currentBudgetItem = payload;
  },
  setCurrentAssessment(state, payload) {
    state.currentAssessment = payload;
  },
  setCurrentRisk(state, payload) {
    state.currentRisk = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
