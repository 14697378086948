import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import organisation from "./modules/organisation";
import caseModule from "./modules/case";
import userModule from "./modules/user";
import appModule from "./modules/app";
import goalModule from "./modules/goal";
import incidentModule from "./modules/incident";
import rosterModule from "./modules/roster";
import progressNoteModule from "./modules/progressNote"
import migrationModule from "./modules/migration"

Vue.use(Vuex);

let initialState = {
  organisation: organisation.state,
  caseModule: caseModule.state,
  userModule: userModule.state,
  appModule: appModule.state,
  goalModule: goalModule.state,
  incidentModule: incidentModule.state,
  rosterModule: rosterModule.state,
  progressNoteModule: progressNoteModule.state,
  migrationModule: migrationModule.state
};

// Create a new store
export default new Vuex.Store({
  modules: {
    organisation,
    caseModule,
    userModule,
    goalModule,
    progressNoteModule,
    incidentModule,
    rosterModule,
    appModule,
    migrationModule
  },
  plugins: [createPersistedState()],
  mutations: {
    reset(state) {
      Object.keys(state).forEach(key => {
        if (state[key] != null && state[key] != undefined) {
          Object.assign(
            state[key],
            initialState[key] == undefined ? null : initialState[key]
          );
        }
      });
    }
  }
});
