import { db } from "../main";
import Vue from "vue";
import { UserSummary } from "../models/userSummary";

function getRef() {
  return Vue.prototype.$db
    .collection("organisations")
}

export async function getById(id) {
  const ref = db.collection("organisations");
  const query = await ref.doc(id).get();
  return query.data();
}

export async function list() {
  return getRef().get();
}

export async function listEnabled() {
  return getRef()
  .where("isEnabled", "==", true)
  .get();
}


export async function save(payload, user) {
  const userSummary =  Object.assign({}, new UserSummary(user));
  if (payload.status == undefined || payload.status == null) {
    payload.createdOn = new Date();
    payload.createdBy = userSummary;
  }
  payload.ModifiedOn = new Date();
  payload.modifiedBy = userSummary;
  if (payload.medicalContacts == undefined || payload.medicalContacts == null) {
    payload.medicalContacts = { items: [] };
  }
  if (payload.contacts == undefined || payload.contacts == null) {
    payload.contacts = [];
  }
  if (payload.id != undefined) {
    return db
      .collection("organisations")
      .doc(payload.id)
      .update(payload);
  } else {
    return db.collection("organisations").add(payload);
  }
}
export function registerOrganisationListener(userStore, id) {
  Vue.prototype.$db
    .collection("organisations")
    .doc(id)
    .onSnapshot(doc => {
      // var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
      // console.log(source, " data: ", doc.data());
      userStore.commit("setCurrentOrganisation", doc.data());
    });
}

export async function addUsers(orgId ,users)
{
  const data={users: users}
  Vue.prototype.$db
    .collection("organisations").doc(orgId).set(data);
  
}