<template>
  <span :class="'uploader ' + classes">
    <v-btn @click.native="selectFile" :text="text" v-if="!uploading">
      {{buttonText}}
      <v-icon right aria-hidden="true">mdi-cloud-upload</v-icon>
    </v-btn>
    <input
      id="files"
      type="file"
      name="file"
      ref="uploadInput"
      :multiple="false"
      :accept="(fileTypes!=undefined && fileTypes.length>0?fileTypes:'')"
      @change="detectFiles($event)"
    />
    <v-progress-linear
      v-if="uploading && !uploadEnd"
      class="mt-2"
      :size="100"
      :width="15"
      :value="progressUpload"
      color="primary"
    ></v-progress-linear>
  </span>
</template>

<script>
import firebase from "@firebase/app";
import "@firebase/storage";
import moment from "moment";
export default {
  data() {
    return {
      progressUpload: 0,
      fileName: "",
      originalFileName:"",
      uploadTask: {},
      uploading: false,
      uploadEnd: false,
      downloadURL: "",
      refURL: ""
    };
  },
  props: {
    path: String,
    buttonText: String,
    fileTypes: String,
    classes: String,
    text: [Boolean, String]
  },
  mounted() {},
  computed: {},
  methods: {
    selectFile() {
      this.$refs.uploadInput.click();
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      });
    },
    upload(file) {
      this.originalFileName = file.name;
      const dt = moment(new Date());
      const time = dt.format("YYYY-MM-DD-HHmmss")
      let str = file.name;
      var n = str.lastIndexOf(".");
  
      this.fileName =str.substring(0,n) + "-" +time + str.substring(n,str.length);
      this.uploading = true;
      this.uploadEnd = false;
      this.refURL = this.path + this.fileName ;
      // Get a reference to the storage service, which is used to create references in your storage bucket
      var storage = firebase.storage();

      // Create a storage reference from our storage service
      var storageRef = storage.ref(this.refURL);

      // let storageRef = firebase.storage.ref("images/" + file.name);
      this.uploadTask = storageRef.put(file);
    },
    deleteImage() {
      firebase.storage
        .ref(this.refURL)
        .delete()
        .then(() => {
          this.uploading = true;
          this.uploadEnd = false;
          this.downloadURL = "";
        })
        .catch(error => {
          console.error(`file delete error occured: ${error}`);
        });
    }
  },
  watch: {
    uploadTask: function() {
      this.uploadTask.on(
        "state_changed",
        snapshot => {
          this.progressUpload = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        null,
        () => {
          let me = this;
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploading = false;
            this.downloadURL = downloadURL;
            console.log(me.uploadTask);
            this.$emit("uploadComplete", {
              downloadURL: downloadURL,
              refURL: me.refURL,
              filename: me.fileName,
              originalFileName: me.originalFileName
            });
          });
        }
      );
    }
  }
};
</script>

<style>
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.uploader .v-btn {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
</style>