import Vue from "vue";

import { Subject } from "rxjs";
import { combineLatest } from "rxjs";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";

function getRef(orgId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("events");
}

export async function getById(orgId, id) {
  return getRef(orgId)
    .doc(id)
    .get();
}

export async function deleteById(orgId, id) {
  return getRef(orgId)
    .doc(id)
    .delete();
}

export async function list(orgId) {
  return getRef(orgId).get();
}

export async function save(orgId, payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef(orgId)
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef(orgId).add(obj);
  }
}

export async function getByDatesAndUserId(orgId, viewStart, viewEnd, userId) {
  const startRef = getRef(orgId)
    .where("startDate", ">=", viewStart)
    .where("startDate", "<=", viewEnd)
    .where("staffIds", "array-contains", userId);

  //or
  const endRef = getRef(orgId)
    .where("endDate", ">=", viewStart)
    .where("endDate", "<=", viewEnd)
    .where("staffIds", "array-contains", userId);
  //or

  const multiDayRef = getRef(orgId)
    .where("endDate", ">=", viewEnd)
    .where("isMultiDayEvent", "==", true)
    .where("staffIds", "array-contains", userId);

  // Create Observables.
  let startEvents$ = new Subject();
  let endEvents$ = new Subject();
  let multiDayEvents$ = new Subject();

  // Hook values from callback to the observable
  startRef.onSnapshot(querySnapshot => {
    var data = querySnapshot.docs.map(doc => {
      let data = doc.data();
      data.id = doc.id;
      return data;
    });
    startEvents$.next(data);
  });

  endRef.onSnapshot(querySnapshot => {
    var data = querySnapshot.docs.map(doc => {
      let data = doc.data();
      data.id = doc.id;
      return data;
    });
    endEvents$.next(data);
  });

  multiDayRef.onSnapshot(querySnapshot => {
    var data = querySnapshot.docs.map(doc => {
      let data = doc.data();
      data.id = doc.id;
      return data;
    });
    multiDayEvents$.next(data);
  });

  // combine observables
  var combined = combineLatest(startEvents$, endEvents$, multiDayEvents$).pipe(
    switchMap(data => {
      var [startResults, endResults, multiDayResults] = data;
      var combined = [];
      combined.push(...startResults);
      combined.push(...endResults);
      combined.push(...multiDayResults);

      var reduced = [];
      combined.forEach(element => {
        if (!reduced.some(x => x.id == element.id)) {
          reduced.push(element);
        }
      });
      return of(reduced);
    })
  );
  return combined;
}

export async function getByDates(orgId, viewStart, viewEnd) {
  const startRef = getRef(orgId)
    .where("startDate", ">=", viewStart)
    .where("startDate", "<=", viewEnd);

  //or
  const endRef = getRef(orgId)
    .where("endDate", ">=", viewStart)
    .where("endDate", "<=", viewEnd);
  //or

  const multiDayRef = getRef(orgId)
    .where("endDate", ">=", viewEnd)
    .where("isMultiDayEvent", "==", true);

  // Create Observables.
  let startEvents$ = new Subject();
  let endEvents$ = new Subject();
  let multiDayEvents$ = new Subject();

  // Hook values from callback to the observable
  startRef.onSnapshot(querySnapshot => {
    var data = querySnapshot.docs.map(doc => {
      let data = doc.data();
      data.id = doc.id;
      return data;
    });
    startEvents$.next(data);
  });

  endRef.onSnapshot(querySnapshot => {
    var data = querySnapshot.docs.map(doc => {
      let data = doc.data();
      data.id = doc.id;
      return data;
    });
    endEvents$.next(data);
  });

  multiDayRef.onSnapshot(querySnapshot => {
    var data = querySnapshot.docs.map(doc => {
      let data = doc.data();
      data.id = doc.id;
      return data;
    });
    multiDayEvents$.next(data);
  });

  // combine observables
  var combined = combineLatest(startEvents$, endEvents$, multiDayEvents$).pipe(
    switchMap(data => {
      var [startResults, endResults, multiDayResults] = data;
      var combined = [];
      combined.push(...startResults);
      combined.push(...endResults);
      combined.push(...multiDayResults);

      var reduced = [];
      combined.forEach(element => {
        if (!reduced.some(x => x.id == element.id)) {
          reduced.push(element);
        }
      });
      return of(reduced);
    })
  );
  return combined;
}

export async function guestSearch(orgId, term) {
  var searchTerm = new String(term);

  let participantRef = Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("cases")
    .where("tags", "array-contains", searchTerm.toLowerCase());

  let userRef = Vue.prototype.$db
    .collection("users")
    .where("organisationId", "==", orgId)
    .where("tags", "array-contains", searchTerm.toLowerCase());

  // Create Observables.
  let participants$ = new Subject();
  let users$ = new Subject();

  // Hook values from callback to the observable
  participantRef.onSnapshot(querySnapshot => {
    var data = querySnapshot.docs.map(d => d.data());
    participants$.next(data);
  });

  userRef.onSnapshot(querySnapshot => {
    var data = querySnapshot.docs.map(d => d.data());
    users$.next(data);
  });

  let combined = combineLatest(participants$, users$).pipe(
    switchMap(data => {
      var [participantsResults, usersResults] = data;
      var combined = [];
      participantsResults.map(data => {
        combined.push({
          name:
            data.participantDetails.firstname +
            " " +
            (data.participantDetails.lastname === undefined
              ? ""
              : data.participantDetails.lastname),
          email: data.participantDetails.email,
          type: "Participant",
          id: data.id,
          avatar:
            data.participantDetails.photoURL == undefined ||
            data.participantDetails.photoURL.downloadURL === undefined
              ? ""
              : data.participantDetails.photoURL.downloadURL
        });
      });

      usersResults.map(data => {
        combined.push({
          name:
            data.firstName +
            " " +
            (data.lastName === undefined ? "" : data.lastName),
          email: data.email,
          type: "Staff",
          id: data.id,
          avatar: data.photoURL
        });
      });
      // combined.push(...participantsResults);
      //combined.push(...usersResults);

      var reduced = [];
      combined.forEach(element => {
        if (!reduced.some(x => x.id == element.id)) {
          reduced.push(element);
        }
      });

      return of(reduced);
    })
  );
  return combined;
}
