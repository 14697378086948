import Vue from "vue";

function getRef(orgId, caseId, goalId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("cases")
    .doc(caseId)
    .collection("goals")
    .doc(goalId)
    .collection("progressNotes");
}

export async function getById(orgId, caseId, goalId, id) {
  return getRef(orgId, caseId, goalId)
    .doc(id)
    .get();
}

export async function list(orgId, caseId, goalId) {
  return getRef(orgId, caseId, goalId)
    .where("isDeleted", "==", false)
    .get();
}

export async function listRemoved(orgId, caseId, goalId) {
  return getRef(orgId, caseId, goalId)
    .where("isDeleted", "==", true)
    .get();
}

export async function listByBudgetId(orgId, caseId, goalId, bid)
{
  return getRef(orgId, caseId, goalId)
    .where("isDeleted", "==", true)
    .where("budetItem.id","==",bid)
    .get();
}
export async function listAllForOrganisation(
  organisationId,
  fromDate,
  toDate,
  nDISManaged,
  planManaged,
  user
) {
  let fromDt = new Date();
  let toDt = new Date();

  if (fromDate !== undefined) {
    fromDt = new Date(fromDate);

    fromDt.setHours(0);
    fromDt.setMinutes(0);
    fromDt.setSeconds(0);
  }
  if (toDate !== undefined) {
    toDt = new Date(toDate);
    toDt.setHours(23);
    toDt.setMinutes(59);
    toDt.setSeconds(59);
  }
  console.log(fromDt + " " + toDt);
  if (!nDISManaged && !planManaged) {
    return;
  }
  let ref = Vue.prototype.$db
    .collectionGroup("progressNotes")
    .where("endDate", ">=", fromDt)
    .where("endDate", "<=", toDt)
    .where("organisationId", "==", organisationId)
    .where("isDeleted", "==", false)
    
  if (user != undefined) {
    ref = ref.where("createdBy.id", "==", user.id);
  }
  if (nDISManaged && !planManaged) {
    return ref.where("lineItem.nDISManaged", "==", true).get();

  } else if (planManaged && !nDISManaged) {
    console.log("Plan Managed");
    return ref.where("lineItem.nDISManaged", "==", false).get();
  } else {

    return ref.get();
  }
}

export function validateProgressNote(note) {
  let errors = [];

  if (note.nDISNumber === undefined) {
    errors.push({
      description: "NDIS number is not set for the participant record."
    });
  }
  if (note.budgetItem === undefined) {
    errors.push({
      description: "A valid budget item must be selected."
    });
  }
  if (note.lineItem === undefined) {
    errors.push({
      description: "A valid line item must be selected."
    });
  }

  if (note.gstType === undefined) {
    errors.push({
      description: "A valid GST type must be selected."
    });
  }
  if (note.claimType === undefined) {
    errors.push({
      description: "A valid claim type must be selected."
    });
  }

  if (
    note.claimType !== undefined &&
    note.claimType.code == "CANC" &&
    note.cancellationReason === undefined
  ) {
    errors.push({
      description: "A valid cancelation reason must be specified when Claim type is 'Cancellation Charges'"
    });
  }

  if (note.startDate === undefined) {
    errors.push({
      description: "A valid start date and time must be selected."
    });
  }

  if (note.endDate === undefined) {
    errors.push({
      description: "A valid end date and time must be selected."
    });
  }

  // if (note.lineItem !== undefined) {
  //   if (note.lineItem.priceControl !== "Y" && note.price === undefined) {
  //     errors.push({ description: "Price per item must be specified" });
  //   }
  // }
  return {
    valid: errors.length === 0,
    errors: errors
  };
}
export async function remove(orgId, caseId, goalId, id, user) {
  return getRef(orgId, caseId, goalId)
    .doc(id)
    .update({
      isDeleted: true,
      deletedBy: user,
      deletedOn: new Date()
    });
}

export async function unRemove(orgId, caseId, goalId, id) {
  return getRef(orgId, caseId, goalId)
    .doc(id)
    .update({
      isDeleted: false,
      deletedBy: null,
      deletedOn: null
    });
}

export async function save(orgId, caseId, goalId, payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef(orgId, caseId, goalId)
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef(orgId, caseId, goalId).add(obj);
  }
}