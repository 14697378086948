<template>
  <div>
    <v-menu
      ref="pickerMenu"
      v-model="pickerMenu"
      :close-on-content-click="false"
      transition="v-scale-transition"
      offset-y
      :nudge-left="40"
      content-class="datetime-picker"
    >
      <template v-slot:activator="{ on }">
        <v-text-field :label="label" v-model="actualDatetime" readonly v-on="on"></v-text-field>
      </template>
      <div class="datetime-picker-dialog">
        <div class="date">
          <v-date-picker v-model="dateModel" no-title scrollable actions @input="checkHours"></v-date-picker>
        </div>
        <div class="time">
          <v-time-picker
            ref="timer"
            v-model="timeModel"
            no-title
            scrollable
            format="24hr"
            :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55,60]"
            actions
            @input="checkMinutes"
          ></v-time-picker>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    datetime: {
      required: true
    },
    label: {
      default: ""
    }
  },
  data() {
    return {
      dateModel: "",
      timeModel: "",
      pickerMenu: false,
      selectedTab: "calendar"
    };
  },

  watch: {
    pickerMenu(val) {
      if (val) {
        this.selectedTab = "calendar";
        if (this.$refs.timer) this.$refs.timer.selectingHour = true;
      }
    }
  },
  computed: {
    actualDatetime() {
      
      return moment(
        new Date(this.dateModel + " " + this.timeModel + ":00")
      ).format("DD-MM-YYYY HH:mm");
    }
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    checkMinutes(val) {
      if (this.$refs.timer.selectingHour === false) {
        this.timeModel = val;
        this.$refs.timer.selectingHour = true;
        this.selectedTab = "calendar";
        this.pickerMenu = false;
        this.$emit("input", this.actualDatetime);
      }
    },
    checkHours(val) {
      this.dateModel = val;
      this.selectedTab = "timer";
    }
  },
  created() {
    let dt = new Date();
  
    console.log(this.datetime);
    if (this.datetime != undefined) {
      dt = this.datetime;

    }
    console.log(dt);
  
    this.dateModel = dt.toISOString().split("T")[0];
    this.timeModel = dt
      .toISOString()
      .split("T")[1]
      .substring(0, 5);
  }
};
</script>
<style>
.datetime-picker-dialog {
  display: flex;
  flex-wrap: wrap;
}
</style>